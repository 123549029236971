export const clientNameFooter = "MARINA PUMAR";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '5px';

export const nomeFornecedorCompleto = "MARINA PUMAR MARKETING E EVENTOS LTDA";
export const nomeFornecedor = "MP360";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com",
    profile: "@blix.aplicativos"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com",
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/message/KWKSZPRGPD2YI1",
    number: "(51) 98032-5196"
  },
];

export const drawerClosedLinks = [
  // {
  //   title: "Fale Comigo",
  //   link: "https://instagram.com",
  // },
];

export const linkAppleStore = "https://apps.apple.com/us/app/mp360/id6446199723"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_marinapumar.twa"

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [{
  title: "Assinar planos",
  link: "https://mp360.com.br/",
}];
